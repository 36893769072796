<template>
  <div id="app">
    <h1>Odontograma Anatômico</h1>

    <!-- Barra de status -->
    <div class="status-bar">
      <div
        class="status"
        v-for="(color, status) in statuses"
        :key="status"
        :class="{ active: currentStatus === status }"
        @click="setCurrentStatus(status)"
      >
        <div class="status-color" :class="status"></div>
        <span>{{ statusLabels[status] }}</span>
      </div>
    </div>

    <!-- Odontograma -->
    <div class="odontogram">
      <div class="tooth-container" v-for="tooth in teeth" :key="tooth.number">
        <div class="tooth-number">{{ tooth.number }}</div>
        <div class="tooth" @click="applyStatus(tooth)">
          <div class="crown">
            <div
              class="surface mesial"
              :class="tooth.surfaces.mesial"
              @click.stop="selectSurface(tooth, 'mesial')"
            ></div>
            <div
              class="surface distal"
              :class="tooth.surfaces.distal"
              @click.stop="selectSurface(tooth, 'distal')"
            ></div>
            <div
              class="surface occlusal"
              :class="tooth.surfaces.occlusal"
              @click.stop="selectSurface(tooth, 'occlusal')"
            ></div>
            <div
              class="surface lingual"
              :class="tooth.surfaces.lingual"
              @click.stop="selectSurface(tooth, 'lingual')"
            ></div>
            <div
              class="surface buccal"
              :class="tooth.surfaces.buccal"
              @click.stop="selectSurface(tooth, 'buccal')"
            ></div>
          </div>
          <div
            class="root"
            :class="tooth.surfaces.root"
            @click.stop="selectSurface(tooth, 'root')"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentStatus: 'healthy',
      statuses: {
        healthy: '#8bc34a',
        caries: '#f44336',
        restauracao: '#ffeb3b',
        extraido: '#000'
      },
      statusLabels: {
        healthy: 'Saudável',
        caries: 'Cárie',
        restauracao: 'Restauração',
        extraido: 'Extraído'
      },
      teeth: []
    }
  },
  methods: {
    initTeeth() {
      const numbers = [
        18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 48, 47,
        46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38
      ]

      this.teeth = numbers.map((number) => ({
        number,
        surfaces: {
          mesial: 'healthy',
          distal: 'healthy',
          occlusal: 'healthy',
          lingual: 'healthy',
          buccal: 'healthy',
          root: 'healthy'
        }
      }))
    },
    setCurrentStatus(status) {
      this.currentStatus = status
    },
    selectSurface(tooth, surface) {
      tooth.surfaces[surface] = this.currentStatus
    },
    applyStatus(tooth) {
      Object.keys(tooth.surfaces).forEach((surface) => {
        tooth.surfaces[surface] = this.currentStatus
      })
    }
  },
  mounted() {
    this.initTeeth()
  }
}
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.status-bar {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.status {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border-color 0.2s ease;
}

.status.active {
  border-color: #000;
}

.status-color {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 50%;
}

.odontogram {
  display: grid;
  grid-template-columns: repeat(8, 70px);
  gap: 15px;
  justify-content: center;
}

.tooth-container {
  text-align: center;
}

.tooth {
  width: 50px;
  height: 90px;
  position: relative;
  cursor: pointer;
}

.crown,
.root {
  background-color: #fff;
  border: 2px solid #333;
  border-radius: 5px;
}

.crown {
  height: 60px;
  overflow: hidden;
  position: relative;
}

.root {
  width: 25px;
  height: 30px;
  margin: 0 auto;
  border-top: none;
}

.surface {
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.surface.mesial {
  left: 0;
  width: 25%;
  height: 100%;
}
.surface.distal {
  right: 0;
  width: 25%;
  height: 100%;
}
.surface.occlusal {
  width: 50%;
  height: 30%;
  left: 25%;
  top: 0;
}
.surface.lingual {
  left: 25%;
  width: 50%;
  height: 70%;
  bottom: 0;
}
.surface.buccal {
  right: 25%;
  width: 50%;
  height: 70%;
  bottom: 0;
}

/* Status Colors */
.healthy {
  background-color: #8bc34a !important;
}
.caries {
  background-color: #f44336 !important;
}
.restauracao {
  background-color: #ffeb3b !important;
}
.extraido {
  background-color: #000 !important;
}
</style>
